import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DIV, IMG, CLIPPATH, VIDEO } from '../../models';
import {
  getObjectIsSelected,
  getIsObjectLocked,
  selectObjectOnly,
  setCurrentObjectID,
  clearObjectsSelection,
} from '../../editor';
import { PopoverTrigger } from '../../ui';
import { getObjectByID } from '../../project';
import { createSelectable } from '../../DragSelect';
import { withEditableState } from '../../EditableContext';
import DraggableObject from './DraggableObject';

const SelectableDraggableObject = createSelectable(
  DraggableObject,
  props => props.object.id,
);

const ObjectEditable = ({
  object,
  isLocked,
  setToCurrent,
  selectIfNotSelected,
}) => {
  if (!object) {
    return null;
  }

  switch (object.type) {
    case CLIPPATH:
      return <DraggableObject object={object} />;
    case IMG:
    case VIDEO:
      return (
        <PopoverTrigger id="popover-object" onOpen={selectIfNotSelected}>
          {openPopover => (
            <SelectableDraggableObject
              object={object}
              onContextMenu={e => !isLocked && openPopover(e)}
            />
          )}
        </PopoverTrigger>
      );
    case DIV:
      return (
        <PopoverTrigger id="popover-object" onOpen={selectIfNotSelected}>
          {openPopover => (
            <SelectableDraggableObject
              object={object}
              onContextMenu={e => !isLocked && openPopover(e)}
              onDoubleClick={e => !isLocked && setToCurrent(e)}
            />
          )}
        </PopoverTrigger>
      );
    default:
      return null;
  }
};

ObjectEditable.defaultProps = {
  object: null,
};

ObjectEditable.propTypes = {
  object: PropTypes.object,
  isLocked: PropTypes.bool.isRequired,
  setToCurrent: PropTypes.func.isRequired,
  selectIfNotSelected: PropTypes.func.isRequired,
};

const container = connect(
  (state, { objectID, editableState }) => {
    const object =
      editableState.objects.get(objectID) || getObjectByID(state, objectID);

    if (!object) {
      return {
        isLocked: true,
        isSelected: false,
        object: null,
      };
    }

    const isLocked = getIsObjectLocked(state, object.id);
    return {
      isLocked,
      isSelected: getObjectIsSelected(object.id, state),
      object,
    };
  },
  {
    selectObjectOnly,
    setCurrentObjectID,
    clearObjectsSelection,
  },
  ({ object, isSelected, ...stateProps }, actions, ownProps) => ({
    object,
    isSelected,
    ...stateProps,
    ...ownProps,

    setToCurrent(event) {
      event.preventDefault();
      event.stopPropagation();
      actions.setCurrentObjectID(object.id);
      actions.clearObjectsSelection();
    },

    selectIfNotSelected() {
      if (!isSelected) {
        actions.selectObjectOnly(object.id);
      }
    },
  }),
);

export default withEditableState(container(ObjectEditable));
