import React from 'react';
import { Field } from 'formik';
import { createUseStyles } from 'react-jss';
import { string, arrayOf } from 'prop-types';
import { CheckboxWithLabel } from '../../ui';

const useStyles = createUseStyles({
  pieceSelector: {
    marginBottom: 24,
  },
  title: {
    fontSize: 16,
    letterSpacing: 0.47,
    marginBottom: 8,
  },
  grid: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  gridItem: {
    width: 185,
    margin: [5, 0],
  },
});

const PieceSelector = ({ templateName, pieces }) => {
  const classes = useStyles();
  return (
    <Field name={templateName}>
      {({ field, form }) => (
        <div className={classes.pieceSelector}>
          <div className={classes.title} />
          <div className={classes.grid}>
            {pieces.map(piece => (
              <div key={piece} className={classes.gridItem}>
                <CheckboxWithLabel
                  checked={field.value.pieces.includes(piece)}
                  label={piece}
                  onChange={() => {
                    if (field.value.pieces.includes(piece)) {
                      form.setFieldValue(templateName, {
                        ...field.value,
                        pieces: field.value.pieces.filter(
                          value => value !== piece,
                        ),
                      });
                    } else {
                      form.setFieldValue(templateName, {
                        ...field.value,
                        pieces: field.value.pieces.concat(piece),
                      });
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Field>
  );
};

PieceSelector.propTypes = {
  templateName: string.isRequired,
  pieces: arrayOf(String).isRequired,
};

export default PieceSelector;
