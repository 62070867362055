import React from 'react';
import { createUseStyles } from 'react-jss';
import colors from '../colors';
import { IconOuter, IconInner } from './icons';

const getSize = ({ size = '100%' } = {}) => size;

const useStyles = createUseStyles({
  loadingBox: {
    width: getSize,
    height: getSize,
    position: 'relative',
  },
  '@keyframes loadingAnimation': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  outer: {
    fill: colors.yellow,
    position: 'absolute',
    animation: 'loadingAnimation 1000ms infinite ease-in-out',
  },
  inner: {
    fill: colors.white,
    position: 'absolute',
  },
});

const LoadingIndicator = ({ ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.loadingBox}>
      <IconInner className={classes.inner} />
      <IconOuter className={classes.outer} />
    </div>
  );
};

export default LoadingIndicator;
