import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { List } from 'immutable';
import Downshift from 'downshift';
import { ItemModel, itemToString } from './models';
import Button from './Button';
import Items from './Items';

const useStyles = createUseStyles({
  select: {
    position: 'relative',
    minHeight: 32,
    minWidth: 140,
    display: 'inline-block',
    width: '100%',
  },
});

const Select = ({
  placeholder,
  onChange,
  value,
  items,
  disabled,
  disableSpaceKey,
}) => {
  const positionRef = createRef();

  let stateReducer;
  const classes = useStyles();
  if (disableSpaceKey) {
    stateReducer = (state, changes) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownSpaceButton:
          return state;
        default:
          return changes;
      }
    };
  }

  return (
    <Downshift
      onChange={onChange}
      selectedItem={value}
      itemToString={itemToString}
      stateReducer={stateReducer}
    >
      {downshift => (
        <div className={classes.select}>
          <div ref={positionRef} />
          <Button
            placeholder={placeholder}
            disabled={disabled || !items.size}
            buttonProps={downshift.getToggleButtonProps()}
            {...downshift}
          />
          <Items positionRef={positionRef} items={items} {...downshift} />
        </div>
      )}
    </Downshift>
  );
};

Select.defaultProps = {
  value: undefined,
  items: List(),
  disabled: false,
  disableSpaceKey: false,
  placeholder: 'Select an option',
};

Select.propTypes = {
  items: PropTypes.instanceOf(List),
  value: PropTypes.instanceOf(ItemModel),
  disabled: PropTypes.bool,
  disableSpaceKey: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default Select;
