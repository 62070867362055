/* eslint-disable react/no-did-mount-set-state */
import React, { useState, useRef, useEffect } from 'react';
import { Set } from 'immutable';
import { bool, string, func, object, number } from 'prop-types';
import { useDrop } from 'react-dnd';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import {
  compose,
  identity,
  inverse,
  translate,
  scale,
  applyToPoint,
} from 'transformation-matrix';
import { Types } from '../dnd';
import { getZoomScale } from '../stage';
import {
  clearObjectsSelection,
  addObjectToSelection,
  setSelectedObjects,
  getCurrentObjectID,
  setCurrentObjectID,
  setFocusedArea,
  getFocusedArea,
  STAGE_FOCUSED,
} from '../editor';
import {
  PieceModel,
  makeObject,
  AMP_PROHIBITED_PROPERTIES,
  makeVideoObject,
  VIDEO_ASSET_TYPE,
  IMAGE_ASSET_TYPE,
} from '../models';
import {
  getCurrentPiece,
  createObject,
  getPieceNodeByID,
  getCurrentProjectIsAMP,
} from '../project';
import { getParentMatrix } from '../selectors';
import PieceEditable from '../PieceEditable';
import PanArea from './PanArea';
import StageZoom from './StageZoom';
import PieceSize from './PieceSize';
import DragSelect from '../DragSelect';
import DivTool from './DivTool';
import InsetClipPath from './InsetClipPath';
import { usePanArea, usePanInitialization } from './hooks';

const ASSET_TYPE_MAP = {
  [VIDEO_ASSET_TYPE]: makeVideoObject,
  [IMAGE_ASSET_TYPE]: makeObject,
};

const Stage = ({
  classes,
  pieceID,
  parentObjectID,
  parentMatrix,
  projectIsAMP,
  zoomScale,
  onSelectedObjects,
  nodeStepUp,
  pieceWidth,
  pieceHeight,
  handleStageFocus,
  ...actions
}) => {
  const stageRef = useRef();
  const [
    { pieceLeft, pieceTop },
    updatePanPosition,
    setPanPosition,
  ] = usePanArea();
  const [stageMatrix, setStageMatrix] = useState(identity());
  const [pieceMatrix, setPieceMatrix] = useState(identity());
  useEffect(() => {
    const stageElement = stageRef.current;
    if (!stageElement) {
      return;
    }

    setStageMatrix(translate(stageElement.offsetLeft, stageElement.offsetTop));
  }, [setStageMatrix, stageRef]);
  useEffect(() => {
    setPieceMatrix(compose(scale(zoomScale), translate(pieceLeft, pieceTop)));
  }, [setPieceMatrix, pieceLeft, pieceTop, zoomScale]);
  const [, drop] = useDrop({
    accept: [Types.ASSETS, Types.COLLECTION],
    drop({ assets }, monitor) {
      const stageComponent = stageRef.current;
      if (!stageComponent || !parentObjectID) {
        return;
      }

      actions.clearObjectsSelection();
      const pointer = monitor.getClientOffset();
      const dropPosition = applyToPoint(compose(inverse(parentMatrix)), {
        x: (pointer.x - stageComponent.offsetLeft) / zoomScale - pieceLeft,
        y: (pointer.y - stageComponent.offsetTop) / zoomScale - pieceTop,
      });

      assets.forEach(assetToDrop => {
        const left = dropPosition.x - assetToDrop.width / 2;
        const top = dropPosition.y - assetToDrop.height / 2;

        const createObjectFn = ASSET_TYPE_MAP[assetToDrop.assetType];
        const action = actions.createObject(
          createObjectFn({
            left,
            top,
            pieceID,
            width: assetToDrop.width,
            height: assetToDrop.height,
            assetPath: assetToDrop.path,
            name: assetToDrop.filename,
            editorOptions: {
              prohibitedProperties: projectIsAMP
                ? AMP_PROHIBITED_PROPERTIES
                : [],
            },
          }),
          parentObjectID,
        );

        actions.addObjectToSelection(action.payload.object.id);
      });
    },
  });
  usePanInitialization(stageRef, pieceWidth, pieceHeight, setPanPosition);

  return (
    <div
      className={classes.stage}
      ref={stageRef}
      onDoubleClick={nodeStepUp}
      onFocus={handleStageFocus}
    >
      <DragSelect
        onSelectedItems={onSelectedObjects}
        borderColor="rgba(217,217,217,0.5)"
        fillColor="rgba(217,217,217,0.10)"
        scrollOffsetTop={-pieceTop}
        scrollOffsetLeft={-pieceLeft}
        onScroll={(left, top) => {
          updatePanPosition({ left: -left, top: -top });
        }}
      >
        <div
          role="button"
          tabIndex="-1"
          style={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
            outline: 'none',
            position: 'relative',
            minHeight: 0,
          }}
          ref={drop}
        >
          <PieceEditable left={pieceLeft} top={pieceTop} />
          <DivTool stageRef={stageRef} left={pieceLeft} top={pieceTop} />
          <InsetClipPath stageMatrix={stageMatrix} pieceMatrix={pieceMatrix} />
          <PanArea update={updatePanPosition} />
          <StageZoom />
          <PieceSize width={pieceWidth} height={pieceHeight} />
        </div>
      </DragSelect>
    </div>
  );
};

Stage.defaultProps = {
  parentObjectID: null,
};

Stage.propTypes = {
  addObjectToSelection: func.isRequired,
  classes: object.isRequired,
  createObject: func.isRequired,
  clearObjectsSelection: func.isRequired,
  pieceID: string.isRequired,
  parentObjectID: string,
  pieceWidth: number.isRequired,
  pieceHeight: number.isRequired,
  zoomScale: number.isRequired,
  nodeStepUp: func.isRequired,
  parentMatrix: object.isRequired,
  projectIsAMP: bool.isRequired,
  onSelectedObjects: func.isRequired,
  handleStageFocus: func.isRequired,
};

const style = injectSheet({
  stage: {
    flexGrow: 1,
    outline: 'none',
    position: 'relative',
    minHeight: 0,
  },
});

const container = connect(
  state => {
    const { id, height, width } = getCurrentPiece(state) || PieceModel();
    const parentObjectID = getCurrentObjectID(state);
    const parentNode = getPieceNodeByID(state, id, parentObjectID);
    return {
      pieceWidth: width,
      pieceHeight: height,
      pieceID: id,
      zoomScale: getZoomScale(state),
      parentObjectID,
      parentNode,
      parentMatrix: getParentMatrix(state),
      projectIsAMP: getCurrentProjectIsAMP(state),
      actualFocusedArea: getFocusedArea(state),
    };
  },
  {
    clearObjectsSelection,
    createObject,
    addObjectToSelection,
    setSelectedObjects,
    setCurrentObjectID,
    setFocusedArea,
  },
  (state, actions, ownProps) => ({
    ...state,
    ...actions,
    ...ownProps,
    nodeStepUp(event) {
      const { parentNode } = state;
      if (!parentNode || !parentNode.parentID) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      actions.setCurrentObjectID(parentNode.parentID);
      actions.clearObjectsSelection();
    },
    handleStageFocus: () => {
      if (state.actualFocusedArea !== STAGE_FOCUSED) {
        actions.setFocusedArea(STAGE_FOCUSED);
      }
    },
    onSelectedObjects: (_, items) => actions.setSelectedObjects(Set(items)),
  }),
);

export default container(style(Stage));
