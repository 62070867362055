import React from 'react';
import PropTypes from 'prop-types';
import { DIV, IMG, VIDEO } from '../models';
import DivObject from './DivObject';
import ImgObject from './ImgObject';
import VideoObject from './VideoObject';

const PieceObject = ({ object, imageSrcAttr, ...props }) => {
  switch (object.type) {
    case DIV:
      return (
        <DivObject object={object} {...props}>
          {object.childrens.map(obj => (
            <PieceObject
              key={obj.id}
              object={obj}
              imageSrcAttr={imageSrcAttr}
            />
          ))}
        </DivObject>
      );
    case IMG:
      return <ImgObject object={object} imageSrcAttr={imageSrcAttr} />;
    case VIDEO:
      return <VideoObject object={object} srcAttr={imageSrcAttr} />;
    default:
      return null;
  }
};

PieceObject.propTypes = {
  object: PropTypes.object.isRequired,
  imageSrcAttr: PropTypes.string.isRequired,
};

export default PieceObject;
