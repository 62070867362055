import { Map, List } from 'immutable';
import flatten from 'lodash/flatten';
import {
  unserializeObject,
  updateObjectProperties,
  isDefined,
} from '../models';
import { createReducer } from '../utils';
import * as types from './types';
import { alignObjectsTo } from './align';
import { DistributeObjects, JustifyObjects } from './distributeAndJustify';
import {
  setObjectProperty,
  scaleObjectProperty,
  increaseObjectProperty,
  reduceObjects,
  hasAnimation,
  computeEditorOptions,
  makeAsset,
  getStartAndEndTimeFromKeyframes,
  removeKeyframesBetween,
} from './utils';
import { animateObject } from './animation';

export const extractObjectsFromObject = object => {
  const newObject = object;
  let objectList = [];

  if (newObject.childrens instanceof Array) {
    newObject.childrens.forEach(children => {
      objectList = objectList.concat(extractObjectsFromObject(children));
    });
  }

  const { clipPathObject } = newObject;
  if (clipPathObject) {
    newObject.clipPathObjectID = clipPathObject.id;
    objectList.push(clipPathObject);
  }

  objectList.push(newObject);

  return objectList;
};

const initFromServer = ({ pieces, isAMP }) => () => {
  const objects = flatten(
    pieces.map(piece => extractObjectsFromObject(piece.objects)),
  );

  return Map(
    objects.reduce(
      (curr, obj) => ({
        ...curr,
        [obj.id]: computeEditorOptions(unserializeObject(obj), { isAMP }),
      }),
      {},
    ),
  );
};

const updateObjectsOption = ({ ids, option, value }) => state =>
  reduceObjects(ids, (objects, objectID) =>
    objects.setIn([objectID, option], value),
  )(state);

const updateObjectsProperties = ({
  ids,
  type,
  properties,
  currentTime,
}) => state => {
  switch (type) {
    case types.PROPERTY_INCREMENT:
      return Object.entries(properties).reduce(
        (newState, [property, value]) =>
          reduceObjects(ids, (objects, objectID) =>
            objects.update(
              objectID,
              increaseObjectProperty(property, currentTime, value),
            ),
          )(newState),
        state,
      );
    case types.PROPERTY_SCALE:
      return Object.entries(properties).reduce(
        (newState, [property, value]) =>
          reduceObjects(ids, (objects, objectID) =>
            objects.update(
              objectID,
              scaleObjectProperty(property, currentTime, value),
            ),
          )(newState),
        state,
      );
    case types.PROPERTY_SET:
      return Object.entries(properties).reduce(
        (newState, [property, value]) =>
          reduceObjects(ids, (objects, objectID) =>
            objects.update(
              objectID,
              setObjectProperty(property, currentTime, value),
            ),
          )(newState),
        state,
      );
    default:
      return state;
  }
};

const changeObjectAsset = ({ objectID, asset }) => state =>
  state.update(objectID, object => object.updateAsset(asset));

const distributeObjects = axis => ({ ids, container, currentTime }) => state =>
  new DistributeObjects(axis, ids, state, container, currentTime).evaluate();

const justifyObjects = axis => ({ ids, container, currentTime }) => state =>
  new JustifyObjects(axis, ids, state, container, currentTime).evaluate();

const removeKeyframes = ({ keyframes, currentTime }) => state =>
  keyframes.reduce((oldState, keyframePath, keyframeID) => {
    const [objectID] = keyframePath;
    const path = [...keyframePath, 'keyframes'];
    if (oldState.getIn(path).count() <= 2) {
      return oldState.deleteIn(path);
    }

    return oldState.update(objectID, object =>
      animateObject(currentTime)(
        object.update('keyframes', objKeyframes =>
          objKeyframes.filterNot(keyframe => keyframe.id === keyframeID),
        ),
      ),
    );
  }, state);

const updatePropertyKeyframeTime = ({
  keyframes,
  deltaTime,
  pieceDuration,
}) => state =>
  keyframes.reduce((newState, pathValue, key) => {
    const [oldTime, keyframe] = newState
      .getIn([...pathValue, 'keyframes'])
      .findEntry(({ id }) => id === key);

    let time = parseInt(oldTime, 10) + deltaTime;
    if (time < 0) {
      time = 0;
    } else if (time > pieceDuration) {
      time = pieceDuration;
    }

    return newState
      .deleteIn([...pathValue, 'keyframes', oldTime])
      .setIn([...pathValue, 'keyframes', time], keyframe.set('time', time));
  }, state);

const setKeyframesEasing = ({ keyframes, timingFunction }) => oldState =>
  keyframes.reduce(
    (state, pathValue, keyframeID) =>
      state.updateIn([...pathValue, 'keyframes'], objectKeyframes =>
        objectKeyframes.map(oldKeyframe => {
          if (oldKeyframe.id !== keyframeID) {
            return oldKeyframe;
          }

          return oldKeyframe.set('timingFunction', timingFunction);
        }, keyframes),
      ),
    oldState,
  );

const removePiece = ({ pieceID }) => state =>
  state.filterNot(object => object.pieceID === pieceID);

const updateObjectsWithOldAssetPath = ({
  oldAssetPath,
  newAssetPath,
}) => state =>
  state.map(object => {
    if (object.assetPath !== oldAssetPath) {
      return object;
    }

    return object.set('assetPath', newAssetPath);
  });

const updateObjectsWithAssetPath = asset => state =>
  state.map(object => {
    if (object.assetPath !== asset.path) {
      return object;
    }

    return object.updateAsset(makeAsset(asset));
  });

const toggleObjectAnimation = ({ objectID }) => state =>
  state.updateIn([objectID], object => {
    let value = object.getIn(['editorOptions', 'beingAnimated']);
    if (!(value && hasAnimation(object))) {
      value = !value;
    }

    return object.setIn(['editorOptions', 'beingAnimated'], value);
  });

const updateObjects = ({ objects }) => state =>
  objects.reduce((oldState, object) => oldState.set(object.id, object), state);

const applySavedAnimation = ({ objectID, currentTime, keyframes }) => state => {
  const [startTime, endTime] = getStartAndEndTimeFromKeyframes(keyframes);
  const updatedObject = state
    .get(objectID)
    .setIn(['editorOptions', 'beingAnimated'], true)
    .update('keyframes', removeKeyframesBetween(startTime, endTime))
    .update(object =>
      keyframes.reduce(
        (changedObject, keyframe) =>
          updateObjectProperties(
            animateObject(keyframe.time)(changedObject),
            keyframe.values.reduce((currentProperties, value, property) => {
              if (!isDefined(value)) {
                return currentProperties;
              }

              return { ...currentProperties, [property]: value };
            }, {}),
            keyframe.time,
          ),
        object,
      ),
    )
    .update(object =>
      keyframes.reduce((currentObject, keyframe) => {
        const keyframeTimingFunctionPath = [
          'keyframes',
          keyframe.time,
          'timingFunction',
        ];
        if (!currentObject.hasIn(keyframeTimingFunctionPath)) {
          return currentObject;
        }

        return currentObject.setIn(
          keyframeTimingFunctionPath,
          keyframe.timingFunction,
        );
      }, object),
    );

  return state.setIn([objectID], animateObject(currentTime)(updatedObject));
};

const renameObject = ({ objectID, newName }) => oldState =>
  updateObjectsOption({
    ids: List([objectID]),
    option: 'name',
    value: newName,
  })(oldState);

const resetByID = () => () => Map();

const handlers = {
  [types.INIT_FROM_SERVER]: initFromServer,
  [types.UPDATE_OBJECTS]: updateObjects,
  [types.REMOVE_KEYFRAMES]: removeKeyframes,
  [types.UPDATE_PROPERTY_KEYFRAME_TIME]: updatePropertyKeyframeTime,
  [types.SET_KEYFRAMES_EASING]: setKeyframesEasing,
  [types.ALIGN_OBJECTS_TO_LEFT]: alignObjectsTo('left'),
  [types.ALIGN_OBJECTS_TO_CENTER]: alignObjectsTo('center'),
  [types.ALIGN_OBJECTS_TO_RIGHT]: alignObjectsTo('right'),
  [types.ALIGN_OBJECTS_TO_TOP]: alignObjectsTo('top'),
  [types.ALIGN_OBJECTS_TO_MIDDLE]: alignObjectsTo('middle'),
  [types.ALIGN_OBJECTS_TO_BOTTOM]: alignObjectsTo('bottom'),
  [types.DISTRIBUTE_OBJECTS_CENTER_H]: distributeObjects('x'),
  [types.DISTRIBUTE_OBJECTS_CENTER_V]: distributeObjects('y'),
  [types.DISTRIBUTE_OBJECTS_JUSTIFIED_H]: justifyObjects('x'),
  [types.DISTRIBUTE_OBJECTS_JUSTIFIED_V]: justifyObjects('y'),
  [types.UPDATE_OBJECTS_OPTION]: updateObjectsOption,
  [types.UPDATE_OBJECTS_PROPERTIES]: updateObjectsProperties,
  [types.CHANGE_OBJECT_ASSET]: changeObjectAsset,
  [types.REMOVE_PIECE]: removePiece,
  [types.UPDATE_ASSET_PATH]: updateObjectsWithOldAssetPath,
  [types.ADD_ASSET]: updateObjectsWithAssetPath,
  [types.TOGGLE_OBJECT_ANIMATION]: toggleObjectAnimation,
  [types.APPLY_SAVED_ANIMATION]: applySavedAnimation,
  [types.UNSET_PROJECT]: resetByID,
  [types.RENAME_OBJECT]: renameObject,
};

export default createReducer(Map(), handlers);
