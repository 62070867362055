import { useCallback } from 'react';
import { useStore, useDispatch } from 'react-redux';
import { useMutation, gql } from '@apollo/client';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../Notification';
// FIXME
// Cyclic dependency here, if import from '../project'
import projectSerializer from '../project/selector-graphql-serializer';
import { getCurrentProjectID } from '../project/selectors';
import { setProjectUpdatedAt } from '../project/actions';

export const SAVE_PIECES = gql`
  mutation savePieces(
    $projectID: String!
    $pieces: JSONString!
    $guides: JSONString!
  ) {
    updateProject(id: $projectID, pieces: $pieces, guides: $guides) {
      project {
        id
      }
    }
  }
`;
export const SUCCESS_MESSAGE = 'Project saved successfully!';
export const ERROR_MESSAGE = 'Could not save your project. Try again later.';

const useSavePieces = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const [savePieces] = useMutation(SAVE_PIECES);

  return useCallback(() => {
    const state = store.getState();
    const { pieces, guides } = projectSerializer(state);
    const projectID = getCurrentProjectID(state);

    savePieces({
      variables: {
        projectID,
        pieces: JSON.stringify(pieces),
        guides: JSON.stringify(guides),
      },
    })
      .then(({ data }) => {
        const { id } = data.updateProject.project;
        if (id) {
          dispatch(showSuccessNotification(SUCCESS_MESSAGE));
          const updatedAt = new Date();
          dispatch(setProjectUpdatedAt(updatedAt.toISOString()));
          return;
        }

        throw new Error('Project ID is not defined');
      })
      .catch(() => {
        dispatch(showErrorNotification(ERROR_MESSAGE));
      });
  }, [store, dispatch, savePieces]);
};

export default useSavePieces;
