import React from 'react';
import PropTypes from 'prop-types';
import { IMG, CLIPPATH, VIDEO } from '../../models';
import {
  MultipleImages,
  MultipleObjects,
  InsetClipPath,
  VideoIcon,
} from '../../icons';

const SelectionIcon = ({ computedType }) => {
  switch (computedType) {
    case IMG:
      return <MultipleImages />;
    case CLIPPATH:
      return <InsetClipPath />;
    case VIDEO:
      return <VideoIcon />;
    default:
      return <MultipleObjects />;
  }
};

SelectionIcon.propTypes = {
  computedType: PropTypes.string.isRequired,
};

export default SelectionIcon;
