import { Record, Map, Set } from 'immutable';
import round from 'lodash/round';
import { compose, rotateDEG, applyToPoints } from 'transformation-matrix';
import { makeKeyframe } from '../keyframe';
import { ALL_PROPERTIES, roundProperty } from '../properties';
import { isDefined } from '../utils';
import {
  getTransformedObjectCoordinates,
  getValueByOrigin,
  buildOriginString,
  degreeToRadians,
  LEFT,
  TOP,
  CENTER,
  ORIGINS,
  extractScaleFromMatrix,
} from '../../geometry';
import { getObjectMatrix } from './utils';
import { applyScale, createScale } from '../../math';

export const InheritanceModel = Record({
  fromID: '',
  lastSyncHash: 0,
});

export const EditorOptions = Record({
  beingAnimated: false,
  prohibitedProperties: Set([]),
});

export const baseObject = {
  id: '',
  name: '',
  pieceID: '',

  type: null,
  inheritance: null,
  fullSize: false,

  left: 0,
  top: 0,
  height: 0,
  width: 0,
  opacity: 1,
  rotation: 0,
  originX: 0,
  originY: 0,
  translateX: 0,
  translateY: 0,
  scaleX: 1,
  scaleY: 1,

  keyframes: Map({}),

  // All keys below are not serialized to server
  editorOptions: EditorOptions(),
  clipPathObjectID: null,
};

const setterMap = {
  left: 'setLeft',
  top: 'setTop',
  width: 'setWidth',
  height: 'setHeight',
  opacity: 'setOpacity',
  rotation: 'setRotation',
  originX: 'setOriginX',
  originY: 'setOriginY',
  translateX: 'setTranslateX',
  translateY: 'setTranslateY',
  scaleX: 'setScaleX',
  scaleY: 'setScaleY',
};

const hasKeyframe = (object, currentTime) =>
  !!object.getIn(['keyframes', currentTime]);

const getSetFromKeyframesTime = object => Set(object.get('keyframes').keySeq());

export const excludeProperties = prohibitedProperties => property =>
  !prohibitedProperties.includes(property);

export const createKeyframe = (objectState, properties, time) =>
  properties
    .filter(
      excludeProperties(
        objectState.getIn(['editorOptions', 'prohibitedProperties']),
      ),
    )
    .reduce((object, property) => {
      let newObject = object;
      const value = roundProperty(property, newObject.getIn([property]));
      if (!hasKeyframe(object, time)) {
        newObject = newObject.setIn(
          ['keyframes', time],
          makeKeyframe({
            time,
            values: { [property]: value },
          }),
        );
      }

      return newObject.setIn(['keyframes', time, 'values', property], value);
    }, objectState);

export const fillKeyframes = (oldObject, properties) => {
  let keyframeTimeList = getSetFromKeyframesTime(oldObject);

  if (keyframeTimeList.size === 0) {
    keyframeTimeList = Set([0]);
  }

  return keyframeTimeList.reduce(
    (object, time) => createKeyframe(object, properties, time),
    oldObject,
  );
};

const isFirstKeyframe = (object, property) =>
  !object.hasPropertyKeyframes(property) &&
  object.getIn(['editorOptions', 'beingAnimated']);

export const fillKeyframeProperties = (object, time) => {
  const animatedProperties = ALL_PROPERTIES.filter(property =>
    object.hasPropertyKeyframes(property),
  );

  return createKeyframe(object, animatedProperties, time);
};

const updatePropertyValue = (object, property, value) =>
  object.set(property, value);

const propertiesToBeUpdated = [
  ['originX', 'originY'],
  ['translateX', 'translateY'],
];

export const buildPropertiesToUpdate = property =>
  propertiesToBeUpdated.reduce(
    (curr, properties) => {
      if (properties.indexOf(property) === -1) {
        return curr;
      }

      return properties;
    },
    [property],
  );

export const updateObjectProperties = (
  objectToUpdate,
  properties,
  currentTime = null,
) =>
  Object.keys(properties).reduce((object, property) => {
    let newObject = object;
    const propertiesToUpdate = buildPropertiesToUpdate(property);
    const value = properties[property];

    if (currentTime === null) {
      return updatePropertyValue(newObject, property, value);
    }

    if (isFirstKeyframe(newObject, property) && currentTime !== 0) {
      newObject = fillKeyframes(newObject, propertiesToUpdate);
    }

    newObject = updatePropertyValue(newObject, property, value);

    if (newObject.hasPropertyKeyframes(property)) {
      newObject = createKeyframe(newObject, propertiesToUpdate, currentTime);
      newObject = fillKeyframeProperties(newObject, currentTime);
    }

    return newObject;
  }, objectToUpdate);

export const hasModifiedOrigin = object =>
  round(object.originX, 2) !== round(object.width / 2, 2) ||
  round(object.originY, 2) !== round(object.height / 2, 2);

const defaultOrigin = buildOriginString(LEFT, TOP);

export const applyPivotRotation = (pointToRotate, pivot, angleDegree) => {
  const point = [pointToRotate.x, pointToRotate.y];
  const rotationPivot = [pivot.x, pivot.y];

  point[0] -= rotationPivot[0];
  point[1] -= rotationPivot[1];

  const rotationInRadians = degreeToRadians(angleDegree);

  const [x, y] = point;
  const transformedPoint = [
    x * Math.cos(rotationInRadians) - y * Math.sin(rotationInRadians),
    y * Math.cos(rotationInRadians) + x * Math.sin(rotationInRadians),
  ];

  transformedPoint[0] += rotationPivot[0];
  transformedPoint[1] += rotationPivot[1];

  return {
    x: transformedPoint[0],
    y: transformedPoint[1],
  };
};

const compensateArea = ({
  object,
  origin,
  area,
  widthScale,
  heightScale,
  currentTime,
  parentMatrix,
}) => {
  const rotation = area.angleDeg;
  const areaPivot = area.getCoordinatesFromOriginString(origin);
  const matrix = compose(rotateDEG(-rotation, areaPivot.x, areaPivot.y));
  const coords = applyToPoints(
    matrix,
    getTransformedObjectCoordinates(object, parentMatrix),
  );

  const unrotatedObjPivot = coords[ORIGINS[origin]];
  const objPivot = getTransformedObjectCoordinates(object, parentMatrix)[
    ORIGINS[origin]
  ];

  const distanceX = round(unrotatedObjPivot.x) - round(areaPivot.x);
  const distanceY = round(unrotatedObjPivot.y) - round(areaPivot.y);

  const newDistanceX = distanceX * widthScale - distanceX;
  const newDistanceY = distanceY * heightScale - distanceY;

  const newObjPivot = applyPivotRotation(
    {
      x: objPivot.x + newDistanceX,
      y: objPivot.y + newDistanceY,
    },
    objPivot,
    object.rotation,
  );

  const parentScale = extractScaleFromMatrix(parentMatrix);
  const leftDiff = newObjPivot.x - objPivot.x;
  const topDiff = newObjPivot.y - objPivot.y;

  return object.setPosition(
    object.x + leftDiff / parentScale.scaleX,
    object.y + topDiff / parentScale.scaleY,
    currentTime,
  );
};

export const isReferenceValueToUpdate = (object, properties, currentTime = 0) =>
  properties.every(prop => object[prop] === baseObject[prop]) &&
  (!object.editorOptions.beingAnimated ||
    (currentTime === 0 &&
      object.keyframes.filter(({ values }) =>
        properties.some(prop => isDefined(values[prop])),
      ).size === 0));

const baseMixin = BaseClass =>
  class extends BaseClass {
    get dimensions() {
      return {
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
      };
    }

    get x() {
      return this.left + this.translateX;
    }

    get y() {
      return this.top + this.translateY;
    }

    get matrix() {
      return getObjectMatrix(this);
    }

    scale(
      widthScale,
      heightScale,
      currentTime = null,
      { origin = defaultOrigin, area = null, parentMatrix } = {},
    ) {
      const oldCoords = getTransformedObjectCoordinates(this);

      let updatedObject = updateObjectProperties(
        this,
        {
          scaleX: createScale(
            applyScale(this.dimensions.width, widthScale),
            this.width,
          ),
          scaleY: createScale(
            applyScale(this.dimensions.height, heightScale),
            this.height,
          ),
        },
        currentTime,
      );

      const newCoords = getTransformedObjectCoordinates(updatedObject);
      const xDifference =
        newCoords[ORIGINS[origin]].x - oldCoords[ORIGINS[origin]].x;
      const yDifference =
        newCoords[ORIGINS[origin]].y - oldCoords[ORIGINS[origin]].y;

      updatedObject = updatedObject.setPosition(
        updatedObject.x - xDifference,
        updatedObject.y - yDifference,
        currentTime,
      );

      if (area === null) {
        return updatedObject;
      }

      return compensateArea({
        object: updatedObject,
        origin,
        area,
        widthScale,
        heightScale,
        currentTime,
        parentMatrix,
      });
    }

    setPosition(x, y, currentTime = null) {
      if (
        isReferenceValueToUpdate(
          this,
          ['translateX', 'translateY'],
          currentTime,
        )
      ) {
        return updateObjectProperties(
          this,
          {
            left: x,
            top: y,
          },
          currentTime,
        );
      }

      return updateObjectProperties(
        this,
        {
          translateX: x - this.left,
          translateY: y - this.top,
        },
        currentTime,
      );
    }

    setX(x, currentTime = null) {
      return this.setPosition(x, this.y, currentTime);
    }

    setY(y, currentTime = null) {
      return this.setPosition(this.x, y, currentTime);
    }

    setOrigin(originX, originY, currentTime = null, { parentMatrix } = {}) {
      const oldCoords = getTransformedObjectCoordinates(this, parentMatrix);

      const newObject = updateObjectProperties(
        this,
        { originX, originY },
        currentTime,
      );
      const newCoords = getTransformedObjectCoordinates(
        newObject,
        parentMatrix,
      );

      const oldPosition = getValueByOrigin(oldCoords, CENTER, CENTER);
      const newPosition = getValueByOrigin(newCoords, CENTER, CENTER);

      return newObject.setPosition(
        newObject.x + oldPosition.x - newPosition.x,
        newObject.y + oldPosition.y - newPosition.y,
        currentTime,
      );
    }

    setSize(width, height, currentTime, { parentMatrix } = {}) {
      let updatedObject = this;

      updatedObject = updateObjectProperties(
        this,
        { width, height },
        currentTime,
      );

      if (hasModifiedOrigin(this)) {
        return updatedObject.setOrigin(
          this.originX *
            createScale(updatedObject.dimensions.width, this.dimensions.width),
          this.originY *
            createScale(
              updatedObject.dimensions.height,
              this.dimensions.height,
            ),
          currentTime,
          { parentMatrix },
        );
      }

      return updatedObject.setOrigin(
        updatedObject.width / 2,
        updatedObject.height / 2,
        currentTime,
        {
          parentMatrix,
        },
      );
    }

    setLeft(left, currentTime = null) {
      return updateObjectProperties(this, { left }, currentTime);
    }

    setTop(top, currentTime = null) {
      return updateObjectProperties(this, { top }, currentTime);
    }

    setWidth(width, currentTime = null) {
      return updateObjectProperties(this, { width }, currentTime);
    }

    setHeight(height, currentTime = null) {
      return updateObjectProperties(this, { height }, currentTime);
    }

    setOpacity(opacity, currentTime = null) {
      return updateObjectProperties(this, { opacity }, currentTime);
    }

    setRotation(rotation, currentTime = null) {
      return updateObjectProperties(this, { rotation }, currentTime);
    }

    setOriginX(originX, currentTime = null) {
      return this.setOrigin(originX, this.originY, currentTime);
    }

    setOriginY(originY, currentTime = null) {
      return this.setOrigin(this.originX, originY, currentTime);
    }

    setTranslateX(translateX, currentTime) {
      return updateObjectProperties(this, { translateX }, currentTime);
    }

    setTranslateY(translateY, currentTime) {
      return updateObjectProperties(this, { translateY }, currentTime);
    }

    setScaleX(scaleX, currentTime) {
      return updateObjectProperties(this, { scaleX }, currentTime);
    }

    setScaleY(scaleY, currentTime) {
      return updateObjectProperties(this, { scaleY }, currentTime);
    }

    apply(properties, currentTime = null) {
      return Object.entries(properties).reduce(
        (object, [property, value]) =>
          object[setterMap[property]](value, currentTime),
        this,
      );
    }

    hasPropertyKeyframes(property) {
      return !this.get('keyframes')
        .filterNot(
          keyframe =>
            keyframe.values[property] === undefined ||
            keyframe.values[property] === null,
        )
        .isEmpty();
    }
  };

export default baseMixin;
