import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CLIPPATH } from '../models';
import { getSelectedObjects } from '../editor';
import { extractTypes } from './utils';
import ClipPathTools from './ClipPathTools';
import CommonTools from './CommonTools';

const ActiveTools = ({ selectionSize, hasClipPathSelected }) => {
  if (selectionSize === 1 && hasClipPathSelected) {
    return <ClipPathTools />;
  }

  return <CommonTools />;
};

ActiveTools.propTypes = {
  selectionSize: PropTypes.number.isRequired,
  hasClipPathSelected: PropTypes.bool.isRequired,
};

const container = connect(state => {
  const objects = getSelectedObjects(state);
  const selectedTypes = extractTypes(objects);
  const hasClipPathSelected = selectedTypes.has(CLIPPATH);

  return {
    selectionSize: objects.size,
    hasClipPathSelected,
  };
});

export default container(ActiveTools);
