import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';
import { createUseStyles } from 'react-jss';
import CardColor from '../CardColor/CardColor';
import { ButtonPrimary } from '../Buttons';
import { InputColor } from '../Input/index';
import colors from '../colors';

const useStyles = createUseStyles({
  colorPicker: {
    borderRadius: 8,
    background: colors.black4,
    width: 232,
    height: 384,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .react-colorful': {
      marginTop: 16,
      width: 200,
      height: 232,
      borderRadius: 0,
      '& .react-colorful__saturation': {
        borderBottom: 0,
        height: 200,
        borderRadius: 0,
      },
      '& .react-colorful__hue': {
        width: 200,
        height: 16,
        borderRadius: 20,
        marginTop: 16,
      },
      '& .react-colorful__hue-pointer': {
        width: 16,
        height: 16,
        background: colors.white,
        fill: colors.white,
        '& .react-colorful__pointer-fill': {
          backgroundColor: 'colors.white !important',
          fill: 'colors.white',
        },
      },
      '& .react-colorful__saturation-pointer': {
        width: 12,
        height: 12,
      },
    },
  },
  wrapperCardInput: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    marginTop: 16,
  },
  wrapperButton: {
    width: 117,
    height: 40,
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

const ColorPicker = ({ color, setColor, onClick }) => {
  const [actualColor, setActualColor] = useState(color);
  const classes = useStyles();
  const updateColor = useCallback(() => {
    setColor(actualColor);
    onClick();
  }, [setColor, onClick, actualColor]);

  return (
    <div className={classes.colorPicker}>
      <HexColorPicker
        color={color}
        onChange={value => setActualColor(value.toUpperCase())}
      />
      <div className={classes.wrapperCardInput}>
        <CardColor color={actualColor} big />
        <InputColor
          value={actualColor}
          onChange={setActualColor}
          maxLength="7"
          spellCheck="false"
        />
      </div>
      <div className={classes.wrapperButton}>
        <ButtonPrimary
          type="button"
          onClick={updateColor}
          label="OK"
          extended
        />
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ColorPicker;
