import { createCurrentTimeAction } from '../playback/middlewares';
import { createActionWithParentObjectID } from '../editor/middlewares';
import { getSelectedObjectsIDs, getCurrentObjectID } from '../editor/selectors';
import { getCurrentPieceID } from '../stage';
import { createAction } from '../utils';
import * as types from './types';

// Project
export const initFromServer = ({
  pieces = [],
  guides = [],
  updatedAt = '',
  isAMP = false,
} = {}) => ({
  type: types.INIT_FROM_SERVER,
  payload: { pieces, guides, updatedAt, isAMP },
});

export const initAssetsFromServer = ({ assets, collections }) => ({
  type: types.INIT_ASSETS_FROM_SERVER,
  payload: { assets, collections },
});

export const setCurrentProject = ({ id, name, amp = false }) => ({
  type: types.SET_CURRENT_PROJECT,
  payload: { id, name, amp },
});

export const unsetProject = () => ({
  type: types.UNSET_PROJECT,
});

export const removeObjectsByAssetPath = assetPath => ({
  type: types.REMOVE_OBJECTS_BY_ASSET_PATH,
  payload: { assetPath },
});

export const requestAssets = projectID => ({
  type: types.REQUEST_ASSETS,
  payload: { projectID },
});

export const addAsset = asset => ({
  type: types.ADD_ASSET,
  payload: asset,
});

export const removeAsset = assetPath => ({
  type: types.REMOVE_ASSET,
  payload: { assetPath },
});

export const moveAsset = (oldAssetPath, newAssetPath) => ({
  type: types.MOVE_ASSET,
  payload: { oldAssetPath, newAssetPath },
});

export const updateAssetPath = (oldAssetPath, newAssetPath) => ({
  type: types.UPDATE_ASSET_PATH,
  payload: { oldAssetPath, newAssetPath },
});

export const addCollection = ({ path }) => ({
  type: types.ADD_COLLECTION,
  payload: { path },
});

export const removeCollection = path => ({
  type: types.REMOVE_COLLECTION,
  payload: { path },
});

export const requestRemoveCollection = path => ({
  type: types.REQUEST_REMOVE_COLLECTION,
  payload: { path },
});

export const addSizes = pieces => ({
  type: types.ADD_SIZES,
  payload: {
    pieces,
  },
});

export const setProjectUpdatedAt = updatedAt => ({
  type: types.SET_PROJECT_UPDATED_AT,
  payload: { updatedAt },
});

// Piece properties
export const setPieceDuration = (pieceID, duration) => ({
  type: types.SET_PIECE_DURATION,
  payload: { pieceID, duration },
});

export const createObject = object =>
  createActionWithParentObjectID({
    type: types.CREATE_OBJECT,
    payload: { object },
  });

export const createClipPathObject = object => ({
  type: types.CREATE_CLIPPATH_OBJECT,
  payload: { object },
});

export const duplicateObjects = objects =>
  createActionWithParentObjectID({
    type: types.DUPLICATE_OBJECTS,
    payload: {
      objects,
    },
  });

export const removeObjects = ids => ({
  type: types.REMOVE_OBJECTS,
  payload: { ids },
});

export const removeObjectsByPieceID = pieceID => ({
  type: types.REMOVE_OBJECTS_BY_PIECE_ID,
  payload: { pieceID },
});

export const removePiece = pieceID => ({
  type: types.REMOVE_PIECE,
  payload: { pieceID },
});

export const setUpdatedAt = (pieceID, updatedAt) => ({
  type: types.SET_UPDATED_AT,
  payload: { pieceID, updatedAt },
});

// Piece inheritance
export const setPieceParentID = (childID, parentID) => ({
  type: types.SET_PIECE_PARENT_ID,
  payload: { childID, parentID },
});

export const removePieceParent = pieceID => ({
  type: types.REMOVE_PIECE_PARENT,
  payload: { pieceID },
});

export const copyPieceContent = (fromPiece, toPiece) => ({
  type: types.COPY_PIECE_CONTENT,
  payload: { fromPiece, toPiece },
});

export const updatePieceFromParent = (parentPiece, piece) => ({
  type: types.UPDATE_PIECE_FROM_PARENT,
  payload: { parentPiece, piece },
});

// Objects properties
export const updateObjects = objects => ({
  type: types.UPDATE_OBJECTS,
  payload: { objects },
});

export const updateObjectsOption = ({ ids, option, value }) => ({
  type: types.UPDATE_OBJECTS_OPTION,
  payload: { ids, option, value },
});

export const updateObjectsProperties = type => args =>
  createCurrentTimeAction({
    type: types.UPDATE_OBJECTS_PROPERTIES,
    payload: ['type', 'ids', 'properties'],
  })({ type, ...args });

export const incrementObjectsProperties = updateObjectsProperties(
  types.PROPERTY_INCREMENT,
);

export const scaleObjectsProperties = updateObjectsProperties(
  types.PROPERTY_SCALE,
);

export const setObjectsProperties = updateObjectsProperties(types.PROPERTY_SET);

export const changeObjectAsset = (objectID, asset) => ({
  type: types.CHANGE_OBJECT_ASSET,
  payload: {
    objectID,
    asset,
  },
});

export const applySavedAnimation = (objectID, currentTime, keyframes) => ({
  type: types.APPLY_SAVED_ANIMATION,
  payload: {
    objectID,
    currentTime,
    keyframes,
  },
});

export const renameObject = (objectID, newName) => ({
  type: types.RENAME_OBJECT,
  payload: {
    objectID,
    newName,
  },
});

// Objects Keyframes
export const removeKeyframes = createCurrentTimeAction({
  type: types.REMOVE_KEYFRAMES,
  payload: ['keyframes'],
});

export const updatePropertyKeyframeTime = (
  keyframes,
  deltaTime,
  pieceDuration,
) => ({
  type: types.UPDATE_PROPERTY_KEYFRAME_TIME,
  payload: {
    keyframes,
    deltaTime,
    pieceDuration,
  },
});

export const setKeyframesEasing = (keyframes, timingFunction) => ({
  type: types.SET_KEYFRAMES_EASING,
  payload: { keyframes, timingFunction },
});

// Objects Z positioning (non animable)
export const moveObjectsToTop = createAction(
  createActionWithParentObjectID({
    type: types.MOVE_OBJECTS_TO_TOP,
    payload: ['pieceID', 'ids'],
  }),
);

export const moveObjectsToBottom = createAction(
  createActionWithParentObjectID({
    type: types.MOVE_OBJECTS_TO_BOTTOM,
    payload: ['pieceID', 'ids'],
  }),
);

export const moveObjectsOneStepToBottom = createAction(
  createActionWithParentObjectID({
    type: types.MOVE_OBJECTS_ONE_STEP_TO_BOTTOM,
    payload: ['pieceID', 'ids'],
  }),
);

export const moveObjectsOneStepToTop = createAction(
  createActionWithParentObjectID({
    type: types.MOVE_OBJECTS_ONE_STEP_TO_TOP,
    payload: ['pieceID', 'ids'],
  }),
);

export const moveObjectsToIndex = ({
  pieceID,
  parentObjectID,
  ids,
  index,
}) => ({
  type: types.MOVE_OBJECTS_TO_INDEX,
  payload: {
    pieceID,
    parentObjectID,
    ids,
    index,
  },
});

export const moveSelectedObjectsToIndex = index => (dispatch, getState) => {
  const state = getState();
  const selectedObjectsIds = getSelectedObjectsIDs(state);

  return dispatch(
    moveObjectsToIndex({
      pieceID: getCurrentPieceID(state),
      parentObjectID: getCurrentObjectID(state),
      ids: selectedObjectsIds,
      index,
    }),
  );
};

// Objects alignment
export const alignObjectsToLeft = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_LEFT,
  payload: ['ids', 'container'],
});

export const alignObjectsToCenter = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_CENTER,
  payload: ['ids', 'container'],
});

export const alignObjectsToRight = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_RIGHT,
  payload: ['ids', 'container'],
});

export const alignObjectsToTop = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_TOP,
  payload: ['ids', 'container'],
});

export const alignObjectsToMiddle = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_MIDDLE,
  payload: ['ids', 'container'],
});

export const alignObjectsToBottom = createCurrentTimeAction({
  type: types.ALIGN_OBJECTS_TO_BOTTOM,
  payload: ['ids', 'container'],
});

// Objects distribution
export const distributeObjectsCenterH = createCurrentTimeAction({
  type: types.DISTRIBUTE_OBJECTS_CENTER_H,
  payload: ['ids', 'container'],
});

export const distributeObjectsJustifiedH = createCurrentTimeAction({
  type: types.DISTRIBUTE_OBJECTS_JUSTIFIED_H,
  payload: ['ids', 'container'],
});

export const distributeObjectsCenterV = createCurrentTimeAction({
  type: types.DISTRIBUTE_OBJECTS_CENTER_V,
  payload: ['ids', 'container'],
});

export const distributeObjectsJustifiedV = createCurrentTimeAction({
  type: types.DISTRIBUTE_OBJECTS_JUSTIFIED_V,
  payload: ['ids', 'container'],
});

// Objects editor options
export const toggleObjectAnimation = createAction({
  type: types.TOGGLE_OBJECT_ANIMATION,
  payload: ['objectID'],
});

// Guides
export const createGuide = createAction({
  type: types.CREATE_GUIDE,
  payload: ['guide'],
});

export const deleteGuide = createAction({
  type: types.DELETE_GUIDE,
  payload: ['guideID'],
});

export const moveGuide = createAction({
  type: types.MOVE_GUIDE,
  payload: ['guideID', 'position'],
});
