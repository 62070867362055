import { combineReducers } from 'redux-immutable';
import { editorReducer as editor } from './editor';
import { projectReducer as project } from './project';
import { playbackReducer as playback } from './playback';
import { stageReducer as stage } from './stage';
import { reducer as panelAssets } from './PanelAssets';
import { reducer as notification } from './Notification';
import { reducer as viewPieces } from './ViewPieces';
import resetReducer from './reset';

const appReducer = combineReducers({
  editor,
  project,
  playback,
  stage,

  panelAssets,
  viewPieces,

  notification,
});

export default resetReducer(appReducer);
