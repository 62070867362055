import React from 'react';
import { createUseStyles } from 'react-jss';
import { colors } from '../ui';
import ButtonSelectTool from './ButtonSelectTool';
import ButtonPanTool from './ButtonPanTool';
import ButtonDivTool from './ButtonDivTool';
import ButtonInsetClipPath from './ButtonInsetClipPath';

const useStyles = createUseStyles({
  leftSidebar: {
    background: colors.black4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0,
    width: 48,
    '& button': {
      marginTop: 16,
    },
  },
});

const LeftSidebar = () => {
  const classes = useStyles();
  return (
    <div className={classes.leftSidebar}>
      <ButtonSelectTool />
      <ButtonPanTool />
      <ButtonDivTool />
      <ButtonInsetClipPath />
    </div>
  );
};

export default LeftSidebar;
