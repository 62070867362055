import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { DIV, IMG, VIDEO } from '../models';
import getObjectStyle from './object-style';
import { getIsObjectVisible } from '../editor';
import { getObjectByID, getChildrensFromObject } from '../project';
import { withEditableState } from '../EditableContext';
import { VideoThumb } from '../ui';

const InnerComponent = ({
  object,
  clipObject,
  pieceID,
  childrensID,
  innerStyle,
  isVisible,
}) => {
  if (!isVisible) {
    return null;
  }

  const style = {
    ...getObjectStyle(object, clipObject),
    ...innerStyle,
  };

  switch (object.type) {
    case DIV:
      return (
        <div style={style}>
          {childrensID.map(childrenID => (
            <Objects key={childrenID} objectID={childrenID} pieceID={pieceID} />
          ))}
        </div>
      );
    case IMG:
      return (
        <img
          key={object.id}
          src={object.assetURL}
          alt={object.assetURL}
          style={style}
        />
      );
    case VIDEO:
      return <VideoThumb style={style} />;
    default:
      return null;
  }
};

InnerComponent.defaultProps = {
  innerStyle: {},
  clipObject: null,
};

InnerComponent.propTypes = {
  object: PropTypes.object.isRequired,
  clipObject: PropTypes.object,
  pieceID: PropTypes.string.isRequired,
  childrensID: PropTypes.instanceOf(List).isRequired,
  innerStyle: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
};

const container = connect((state, { objectID, editableState, pieceID }) => {
  const object =
    editableState.objects.get(objectID) || getObjectByID(state, objectID);

  let clipObject =
    editableState.objects.get(object.clipPathObjectID) ||
    getObjectByID(state, object.clipPathObjectID);

  if (!getIsObjectVisible(state, object.clipPathObjectID)) {
    clipObject = null;
  }

  return {
    isVisible: getIsObjectVisible(state, objectID),
    object,
    clipObject,
    childrensID: getChildrensFromObject(state, pieceID, objectID),
  };
});

const Objects = withEditableState(container(InnerComponent));

export default Objects;
