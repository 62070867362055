import { IMG, DIV, CLIPPATH, VIDEO } from '../../models';

export const MIXED = 'mixed-selection';

export const computeSelectedType = selectedTypes => {
  if (selectedTypes.size > 1) {
    return MIXED;
  }

  return selectedTypes.first();
};

export const humanizeType = (computedType, isPlural) => {
  switch (computedType) {
    case IMG:
      return isPlural ? 'images' : 'image';
    case DIV:
      return isPlural ? 'div masks' : 'div mask';
    case CLIPPATH:
      return isPlural ? 'clippath masks' : 'clippath mask';
    case VIDEO:
      return isPlural ? 'videos' : 'video';
    default:
      return isPlural ? 'objects' : 'object';
  }
};

export const createSentence = (size, computedType) => {
  const parts = [];
  const isPlural = size > 1;

  if (isPlural) {
    parts.push(size);
  }

  parts.push(humanizeType(computedType, isPlural));

  if (isPlural) {
    parts.push('are selected');
  }

  return parts.join(' ');
};
